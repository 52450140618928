import React from 'react';
import styles from "./Styles.module.css"
import {ReactComponent as WvTxtLogo} from "../../../../Assets/svg/WatchVault/a-txt-logo.svg";
import {ReactComponent as WvIconLogo} from "../../../../Assets/svg/WatchVault/a-icon-logo.svg";

interface BusinessCardProps {
    name: string;
    company: string;
    companyAppendix: string;
    title: string;
    phoneNum: string;
    location: string;
    address: string;
    email: string;
}

function BusinessCardWidget(props: BusinessCardProps) {

    return (
        <div className={styles.BusinessCard}>
            <div className={styles.card}>
                <div className={styles.cardInner}>
                    <div className={styles.cardFront}>
                        <div className={styles.logoWrapper}>
                            <WvTxtLogo className={styles.logo}/>
                        </div>
                    </div>
                    <div className={styles.cardBack}>
                        <WvIconLogo className={styles.iLogo} />
                        <div className={styles.cardBack__Header}>
                            <h2>{props.name}</h2>
                            <h3>{props.title}</h3>
                        </div>
                        <div className={styles.cardBack__contactInfo}>
                            <div className={styles.cardBack__row}>
                                <label>E-mail:</label>
                                <p>{props.email}</p>
                            </div>
                            <div className={styles.cardBack__row}>
                                <label>Phone:</label>
                                <p>{props.phoneNum}</p>
                            </div>
                            <div className={styles.cardBack__row}>
                                <label>Website:</label>
                                <p>{props.companyAppendix}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BusinessCardWidget;