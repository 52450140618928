const BusinessProfileFixture = {
    name: "Adrian Falch Karlsen",
    company: "Watch Vault AS",
    companyAppendix: "watchvault.org",
    title: "Chief Technology Officer",
    phoneNum: "(+47) 920 41 346",
    location: "1364, Fornebu",
    address: "Martin Linges vei 25",
    email: "adrianfalch@watchvault.org",
}

export default BusinessProfileFixture;