import React, {Component} from 'react';
import BusinessProfileFixture from "../../../../Fixtures/BusinessProfile/BusinessProfile.fixture";
import BusinessCardWidget from "../../Widgets/BusinessCard/BusinessCard.widget";
import DisclaimerWidget from "../../Widgets/Disclaimer/Disclaimer.widget";

class HomeMobile extends Component {
    render() {
        return (
            <div>
                <BusinessCardWidget
                    address={BusinessProfileFixture.address}
                    company={BusinessProfileFixture.company}
                    companyAppendix={BusinessProfileFixture.companyAppendix}
                    email={BusinessProfileFixture.email}
                    location={BusinessProfileFixture.location}
                    name={BusinessProfileFixture.name}
                    phoneNum={BusinessProfileFixture.phoneNum}
                    title={BusinessProfileFixture.title}
                />
                <DisclaimerWidget
                    disclaimerText={"Click on business card to reveal backside"}
                />
            </div>
        );
    }
}

export default HomeMobile;