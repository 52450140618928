import React, {Component} from 'react';
import BusinessCardWidget from "../../Widgets/BusinessCard/BusinessCard.widget"
import BusinessProfileFixture from "../../../../Fixtures/BusinessProfile/BusinessProfile.fixture";
import DisclaimerWidget from "../../Widgets/Disclaimer/Disclaimer.widget";

class HomeDesktop extends Component {
    render() {
        return (
            <div>
                <BusinessCardWidget
                     address={BusinessProfileFixture.address}
                     company={BusinessProfileFixture.company}
                     companyAppendix={BusinessProfileFixture.companyAppendix}
                     email={BusinessProfileFixture.email}
                     location={BusinessProfileFixture.location}
                     name={BusinessProfileFixture.name}
                     phoneNum={BusinessProfileFixture.phoneNum}
                     title={BusinessProfileFixture.title}
                />
                <DisclaimerWidget
                    disclaimerText={"Hover over business card to reveal backside"}
                />
            </div>
        );
    }
}

export default HomeDesktop;