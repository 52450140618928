import React, {Component} from 'react';
import styles from './Styles.module.css'
import { isMobile } from 'react-device-detect';
import HomeMobile from "./Layouts/Mobile/Home.mobile";
import HomeDesktop from "./Layouts/Desktop/Home.desktop";

class HomeView extends Component {
    static className = Object.freeze("HomeView");

    private static getLayout() {
        if (isMobile) return (<HomeMobile />)
        if (!isMobile) return (<HomeDesktop />)
    }

    render() {
        return (
            <div className={styles.Home}>
                { HomeView.getLayout() }
            </div>
        );
    }
}

export default HomeView;