import React from 'react';
import './App.css';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import HomeView from "./Home/Home.view";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path={"/"} element={<HomeView/>}/>
                <Route path={"/business-card"} element={<HomeView/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
