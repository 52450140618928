import React from 'react';
import styles from "./Styles.module.css"

interface DisclaimerWidgetProps {
    disclaimerText: string;
}

function DisclaimerWidget (props: DisclaimerWidgetProps) {

    return (
        <div className={styles.DisclaimerWidget}>
            <p>{props.disclaimerText}</p>
        </div>
    )
}

export default DisclaimerWidget;